@import "variables";

@import "bootstrap/bootstrap-custom";
@import "bootstrap/accordions";
@import "bootstrap/alerts";
@import "bootstrap/badges";
@import "bootstrap/breadcrumbs";
@import "bootstrap/buttons";
@import "bootstrap/cards";
@import "bootstrap/carousel";
@import "bootstrap/dropdown";
@import "bootstrap/forms";
@import "bootstrap/grid";
@import "bootstrap/input-group";
@import "bootstrap/modals";
@import "bootstrap/navigation";
@import "bootstrap/pagination";
@import "bootstrap/popover";
@import "bootstrap/progress";
@import "bootstrap/tables";
@import "bootstrap/datatable";
@import "bootstrap/toast";
@import "bootstrap/tooltip";

/* ############### Switcher STYLES ############### */

@import "switcher/default";
@import "switcher/dark-style";
@import "switcher/rtl";
@import "switcher/switcher-header-styles";
@import "switcher/switcher-menu-styles";
@import "switcher/switcher-styles";

/* ########## LAYOUTS  ########## */
@import "layouts/main-content";
@import "layouts/header";
@import "layouts/footer";
@import "layouts/right-sidebar";
@import "layouts/profile";
@import "layouts/iconbar";
@import "layouts/horizontal";
@import "layouts/side-menu";

/* ############### CUSTOM VENDOR STYLES ############### */
@import "lib/datepicker";
@import "lib/datetimepicker";
@import "lib/jqvmap";
@import "lib/chart";
@import "lib/parsely";
@import "lib/quill";
@import "lib/rangeslider";
@import "lib/select2";
@import "lib/spectrum";
@import "lib/spinner";

/* ############### TEMPLATE STYLES ############### */
@import "template/calendar";
@import "template/chat";
@import "template/contacts";
@import "template/error";
@import "template/invoice";
@import "template/mail";
@import "template/profile";
@import "template/cryptocurrency";
@import "template/ecommerce-pages";
@import "template/notifications";

/* ############### UTILITIES/HELPER CLASSES ############### */
@import "util/background";
@import "util/display";
@import "util/flex";
@import "util/border";
@import "util/height";
@import "util/margin";
@import "util/opacity";
@import "util/padding";
@import "util/position";
@import "util/typography";
@import "util/width";


/* ############### CUSTOM ELEMENT STYLES ############### */
@import "custom/component-cols";
@import "custom/landing";
@import "custom/tags";
@import "custom/tabs";
@import "custom/navbar";
@import "custom/wizard";
@import "custom/p-scroll";
@import "custom/radio";
@import "custom/rating";
@import "custom/timeline";
@import "custom/image";
@import "custom/list";
@import "custom/checkbox";
@import "custom/toggle";
@import "custom/listgroup";
@import "custom/jumborton";
@import "custom/custom-control";
@import "custom/custom-styles";

.ti-pencil:hover{
    color:var(--primary-bg-color);
}
.ti-trash:hover{
    color:red;
}

.pagination2 {
    list-style: none;
    padding-left: 0;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  
    &.isLoading {
      pointer-events: none;
      opacity: .5;
      min-height: unset;
      
      &:before {
        display: none;
      }
    }
  
    li {  
      a {
        color: #71719a;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 0.75rem;
        border-width: 0;
        border: 1px solid #e8e8f7;
        font-weight: 400;
        position: relative;
        margin-left: -1px;
        line-height: 1.25;
        background-color: #ffffff;

        i{
          position: inherit;
        }

        &:hover {
            background-color: #eaedf7;
            border-color: #eaedf7;
        }
  
      }
  
      &.break-me, &.disabled {
        a {
            color: #a8afc7;
            cursor: not-allowed;
        }
      }
      
      &.active2 {
        a {
            background-color: $primary;
            border-color: $primary;
            color: #ffffff;
        }
      }
    }
}

.fileUpload{
  position: relative;
  display: block;
  width: 100%;
  height: 280px;
  box-sizing: border-box;
  border: 2px dashed #dcd9f5;
  border-radius: 3px;
  padding: 0;
  background-color: #f2f1ff;
  background-image: url(../img/fancy_upload.png);
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.85;
  outline: none;
  transition: .2s;

  &:hover{
    opacity: 1;
  }

  input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
  }
}

.fileupload-mini{
  height: 240px;
}

.fileUpload-img{
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  

  i{
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 11;

    &:hover{
      color: red;
    }
  }
}

.ti-menu:hover{
  color: #6259ca;
}
.ti-plus:hover{
  color: #28a745;
}

.checkbox-container{
  position:relative;
  
  input {
    position:absolute;
    width: 0;
    height: 0;
    z-index: -1;
    top: 50%;
    left: 50%;
    opacity: 0;
    visibility:hidden;
    
    &:checked+span:after {
      opacity: 1;
    }
    
    &[disabled] {
      &+span {
        cursor: default;
        
        &:after {
          color: var(--gray-300)
        }
      }
    }
  }
}

.checkbox-container-span{
  padding-left: 28px;
  font-size: 14px;
  cursor: pointer;
  display: block;
  padding-top: 2px;
  padding-bottom: 2px;

  &:before {
    content: "\\e954";
    font-family: icomoon;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 20px;
    color: var(--gray-300);
    line-height: 1;
  }

  &:after {
    content: "\\e965";
    font-family: icomoon;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 20px;
    color: var(--accent);
    line-height: 1;
    opacity: 0;
    transition: .2s;
  }
}


.components-table{
  h6{
    color: #1d212f;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px;
    letter-spacing: 0.2px;
    font-size: 14px;
  }
  &-tr{
    border: 1px solid #e8e8f7;
    padding: 25px;
  }

  &-gallery{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;

    img{
      border-radius: 11px;
    }
  }

  &-flex{
    display: flex;
    justify-content: space-between;
  }
}






.fileuploader-flex{
    display:flex;
    gap: 20px;
    flex-wrap:wrap;
}

.fileuploader-div{
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;

    i{
      width: 30px;
      height: 30px;
      background: #000000c7;
      font-size: 20px;
      position:absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover{
        color: red;
      }
    }

    img{
      width:234px;
      height:224px;
      object-fit: cover;
    }
}

.fileUpload-fullwidth{
  width: 100%;
  height: 150px;
}

.multi-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 40px;
  //gap: 10px;
}


.multi-grid{
  border: 1px solid #e8e8f7;

  &:not(:first-child){
    border-top:none;
  }

  input{
    border: none;
    outline: none;
    padding: 0 10px;

    &:focus{
      color: #000000;
    }

    &:not(:last-child){
      border-right: 1px solid #e8e8f7;
    }
  }
}

.multi-grid-title{
  display: grid;
  grid-template-columns: 1fr 1fr 40px;
  height: 38px;
  border: 1px solid #e8e8f7;

  div{
    padding: 8px 10px 0;
  }
  div:not(:first-child){
    border-left: 1px solid #e8e8f7;
  }

}

.video-upload{
  display: flex;
}

.line-canvas{
}
.pr-10{
    padding-right: 10px;
}
.filter-canvas{
    margin-top: 50px;
}